import { css } from '@emotion/core';

export const wrapper = css`
  max-width: 400px;
  margin: 0 auto;
`;

export const subtitle = css`
  margin-top: 1rem;
`;

export const copyWrapper = css`
  display: flex;
  justify-content: space-between;
`;

export const price = css`
  display: flex;
  justify-content: space-between;
`;

export const number = css`
  padding-left: 0.5rem;
`;

export const iceText = css`
  margin-top: 0.25rem;
`;
