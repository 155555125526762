import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const ConstrainedCenterColumn = ({ children }) => (
  <div className="columns is-centered">
    <div className="column wrapper">{children}</div>
  </div>
);

ConstrainedCenterColumn.propTypes = propTypes;

export default ConstrainedCenterColumn;
