import { css } from '@emotion/core';

import { mobileOnly, tabletAndUp } from '../../styles/mediaQueries';

export const sharedName = css`
  ${mobileOnly(css`
    width: 140px;
  `)};
`;

export const sharedPrice = css`
  text-align: center !important;

  ${tabletAndUp(css`
    width: 21%;
  `)};
`;

export const table = css`
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
`;
