import React from 'react';
import PropTypes from 'prop-types';
import { ClassNames } from '@emotion/core';

import * as styles from './sharedKegTableStyles';

const SIXTH_BARREL = 'Sixth Barrel Keg';
const QUARTER_BARREL = 'Quarter Barrel Keg';
const HALF_BARREL = 'Half Barrel Keg';

const propTypes = {
  kegs: PropTypes.array.isRequired,
};

const KegTableBodyRows = ({ kegs }) => {
  return kegs.map(keg => {
    return (
      <tr key={keg.name}>
        <td css={styles.sharedName}>{keg.name}</td>
        <td css={styles.sharedPrice}>
          <KegPrice
            price={keg.sixthBarrelPrice}
            kegSize={SIXTH_BARREL}
            kegSizeOnSale={keg.kegSizeOnSale}
          />
        </td>
        <td css={styles.sharedPrice}>
          <KegPrice
            price={keg.quarterBarrelPrice}
            kegSize={QUARTER_BARREL}
            kegSizeOnSale={keg.kegSizeOnSale}
          />
        </td>
        <td css={styles.sharedPrice}>
          <KegPrice
            price={keg.halfBarrelPrice}
            kegSize={HALF_BARREL}
            kegSizeOnSale={keg.kegSizeOnSale}
          />
        </td>
      </tr>
    );
  });
};

KegTableBodyRows.propTypes = propTypes;

export default KegTableBodyRows;

const KegPrice = ({ price, kegSize, kegSizeOnSale }) => {
  if (!price) {
    return null;
  }

  return (
    <ClassNames>
      {({ cx }) => (
        <p className={cx({ 'has-text-danger': kegSize === kegSizeOnSale })}>
          ${price}
        </p>
      )}
    </ClassNames>
  );
};
