import { useStaticQuery, graphql } from 'gatsby';

export default function useAllKegsData() {
  const response = useStaticQuery(
    graphql`
      query {
        allContentfulKeg {
          edges {
            node {
              id
              kegName
              kegCategory
              kegSizeOnSale
              sixthBarrelPrice
              quarterBarrelPrice
              halfBarrelPrice
            }
          }
        }
      }
    `
  );

  return normalizeAllKegsData(response);
}

function normalizeAllKegsData({ allContentfulKeg: { edges } }) {
  const normalizedAllKegsData = edges.map(({ node }) => {
    const isKegOnSale = Boolean(node.kegSizeOnSale);
    const category = makeKegCategory(node, isKegOnSale);

    return {
      category,
      isKegOnSale,
      kegSizeOnSale: node.kegSizeOnSale,
      name: node.kegName,
      sixthBarrelPrice: node.sixthBarrelPrice,
      quarterBarrelPrice: node.quarterBarrelPrice,
      halfBarrelPrice: node.halfBarrelPrice,
    };
  });

  return normalizedAllKegsData;
}

function makeKegCategory({ kegCategory }, isKegOnSale) {
  if (!isKegOnSale) {
    return kegCategory;
  }

  return 'Sale Kegs';
}
