import React from 'react';

import SectionTitle from '../global/SectionTitle';
import SectionContainer from '../global/SectionContainer';

import * as styles from './Prices.style';

const Prices = () => (
  <SectionContainer>
    <SectionTitle title="Prices" />
    <div css={styles.wrapper}>
      <h4
        css={styles.subtitle}
        className="subtitle is-4 has-text-centered-tablet"
      >
        Deposits
      </h4>
      <div css={styles.copyWrapper}>
        <div>
          <div>Kegs:</div>
          <div>Pump:</div>
          <div>Using Pump Fee:</div>
        </div>
        <div>
          <div css={styles.price}>
            $ <span css={styles.number}>30</span>
          </div>
          <div css={styles.price}>
            $ <span css={styles.number}>50</span>
          </div>
          <div css={styles.price}>
            $ <span css={styles.number}>10</span>
          </div>
        </div>
      </div>
      <h4
        css={styles.subtitle}
        className="subtitle is-4 has-text-centered-tablet"
      >
        Ice
      </h4>
      <div css={styles.copyWrapper}>
        <div>
          <div>20lb Bag of Ice:</div>
        </div>
        <div>
          <div css={styles.price}>
            $ <span css={styles.number}>3.99</span>
          </div>
        </div>
      </div>
      <p css={styles.iceText}>
        Recommended 4 bags on a hot day, less if not so hot.
      </p>
    </div>
  </SectionContainer>
);

export default Prices;
