import React from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../global/SectionContainer';
import KegTableBodyRows from './KegTableBodyRows';

import * as styles from './sharedKegTableStyles';

const propTypes = {
  kegs: PropTypes.array.isRequired,
};

const KegTable = ({ kegs, category }) => (
  <SectionContainer>
    <h4 className="subtitle is-4 has-text-centered-tablet">{category}</h4>
    <table
      className="table is-narrow is-striped is-size-7-mobile"
      css={styles.table}
    >
      <thead>
        <tr>
          <th css={styles.sharedName}>Keg Name</th>
          <th css={styles.sharedPrice}>
            1/6B
            <ExpandedHeader text="(5 Gallon)" />
          </th>
          <th css={styles.sharedPrice}>
            1/4B
            <ExpandedHeader text="(7.5 Gallon)" />
          </th>
          <th css={styles.sharedPrice}>
            1/2B
            <ExpandedHeader text="(15.5 Gallon)" />
          </th>
        </tr>
      </thead>
      <tbody>
        <KegTableBodyRows kegs={kegs} />
      </tbody>
    </table>
  </SectionContainer>
);

KegTable.propTypes = propTypes;

export default KegTable;

const ExpandedHeader = ({ text }) => {
  return <span className="is-hidden-mobile">{' ' + text}</span>;
};
