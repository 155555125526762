import { css } from '@emotion/core';

export const card = css`
  border: 1px solid rgba(10, 10, 10, 0.1);
  box-shadow: 0 0.5em 1em -0.125em rgba(89, 0, 4, 0),
    0 0px 0 1px rgba(89, 0, 4, 0.01);
`;

export const glow = css`
  ${card};
  border: 1px solid rgba(89, 0, 4, 0.1);
  box-shadow: 0 0.5em 1em -0.125em rgba(89, 0, 4, 0.3),
    0 0px 0 1px rgba(89, 0, 4, 0.01);
`;
