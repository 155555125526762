import React from 'react';

import SectionContainer from '../global/SectionContainer';

import * as styles from './KegTitle.style';

const KegTitle = () => (
  <SectionContainer>
    <div css={styles.wrapper}>
      <h4 className="subtitle is-4 has-text-centered-tablet">
        Usually Stocked Keg Price List
      </h4>
      <p>
        Note*: Some kegs may not be available all the time. We only order
        certain kegs when they are on sale. Prices are subject to change and
        while we do our best to keep prices up to date when the distributor
        changes them, the most current pricing is available via phone: (951) 652
        – 6575.
      </p>
    </div>
  </SectionContainer>
);

export default KegTitle;
