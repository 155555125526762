import React from 'react';

import Layout from '../global/Layout';
import Hero from '../global/Hero';
import KegTable from './KegTable';
import KegRules from './KegRules';
import KegCommonQuestions from './KegCommonQuestions';
import PartyPlanning from './PartyPlanning';
import Prices from './Prices';
import KegTitle from './KegTitle';

import useAllKegsData from '../../hooks/useAllKegsData';

const KEG_CATEGORY_SALE = 'Sale Kegs';
const KEG_CATEGORY_DOMESTIC = 'Domestic';
const KEG_CATEGORY_IMPORT = 'Import';
const KEG_CATEGORY_CRAFT_DOMESTIC = 'Craft Domestic';

const Entry = () => {
  const data = useAllKegsData();

  return (
    <Layout>
      <Hero
        title="Ordering a Keg?"
        subtitle="Read the rules to make sure you have a great time."
      />
      <KegRules />
      <KegCommonQuestions />
      <PartyPlanning />
      <Prices />
      <KegTitle />
      <KegTable
        category={KEG_CATEGORY_SALE}
        kegs={getAllKegsInCategory(data, KEG_CATEGORY_SALE)}
      />
      <KegTable
        category={KEG_CATEGORY_DOMESTIC}
        kegs={getAllKegsInCategory(data, KEG_CATEGORY_DOMESTIC)}
      />
      <KegTable
        category={KEG_CATEGORY_IMPORT}
        kegs={getAllKegsInCategory(data, KEG_CATEGORY_IMPORT)}
      />
      <KegTable
        category={KEG_CATEGORY_CRAFT_DOMESTIC}
        kegs={getAllKegsInCategory(data, KEG_CATEGORY_CRAFT_DOMESTIC)}
      />
    </Layout>
  );
};

export default Entry;

function getAllKegsInCategory(data, desiredCategory) {
  const allKegsInCategory = data.filter(
    ({ category }) => category === desiredCategory
  );

  return sortByNameDescending(allKegsInCategory);
}

function sortByNameDescending(kegs) {
  return kegs.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}
