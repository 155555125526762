import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import SectionTitle from '../global/SectionTitle';
import SectionContainer from '../global/SectionContainer';
import ConstrainedCenterColumn from '../global/ConstrainedCenterColumn';

import './KegCommonQuestions.css';

const KegCommonQuestions = () => (
  <SectionContainer>
    <SectionTitle title="Common Keg Questions" />
    <ConstrainedCenterColumn>
      <Accordion
        preExpanded={['wrong-pump']}
        allowMultipleExpanded
        allowZeroExpanded
      >
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Defective Keg?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>1. We call the supplier and have them examine the keg.</p>
            <p>
              2. It is up to the supplier to authorize a refund or an exchange,
              NOT US.
            </p>
            <p>
              3. The keg must be kept cold at all times or there is nothing the
              supplier can do.
            </p>
            <p>
              4. Too many people do not follow our advice of buying ice for the
              trip home and end up with a ruined keg for their party. Trust our
              advice so you don’t look stupid.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="wrong-pump">
          <AccordionItemHeading>
            <AccordionItemButton>
              Wrong Pump? or Pump Not Working?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              1. If the pump is one of our pumps, we will exchange the pump.
            </p>
            <p>
              2. However, if we are closed when you discover the issue, there is
              nothing we can do. Obviously.
            </p>
            <p>3. Test your pump before you leave.</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Special Pumps?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              1. All European kegs have different pumps (longer tips) than
              American kegs.
            </p>
            <p>2. Guinness has their own pump.</p>
            <p>3. Some German beers have their own pumps.</p>
            <p>4. Make sure you get the right pump!</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </ConstrainedCenterColumn>
    <SectionTitle />
  </SectionContainer>
);

export default KegCommonQuestions;
