import React from 'react';

import SectionTitle from '../global/SectionTitle';
import SectionContainer from '../global/SectionContainer';

import * as styles from './PartyPlanning.style';

const PartyPlanning = () => (
  <SectionContainer>
    <SectionTitle title="Party Planning" />
    <div className="columns is-centered">
      <div className="column">
        <div className="card" css={styles.card}>
          <div className="card-header">
            <p className="card-header-title">1/6 Barrel Keg</p>
          </div>
          <div className="card-content">
            <p>5 Gallon Keg</p>
            <p>640oz</p>
            <p>80 servings</p>
            <p>Serving Size 20 people (avg)</p>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="card" css={styles.card}>
          <div className="card-header">
            <p className="card-header-title">1/4 Barrel Keg</p>
          </div>
          <div className="card-content">
            <p>7.5 Gallon Keg</p>
            <p>992oz</p>
            <p>124 servings</p>
            <p>Serving Size 31 people (avg)</p>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="card" css={styles.glow}>
          <div className="card-header">
            <p className="card-header-title">1/2 Barrel Keg</p>
          </div>
          <div className="card-content">
            <p>15.5 Gallon Keg</p>
            <p>1984oz</p>
            <p>248 servings</p>
            <p>Serving Size 62 people (avg)</p>
          </div>
        </div>
      </div>
    </div>
  </SectionContainer>
);

export default PartyPlanning;
