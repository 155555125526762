import React from 'react';

import SectionTitle from '../global/SectionTitle';
import SectionContainer from '../global/SectionContainer';
import ConstrainedCenterColumn from '../global/ConstrainedCenterColumn';

const KegRules = () => (
  <SectionContainer>
    <SectionTitle title="Keg Rules" />
    <ConstrainedCenterColumn>
      <div className="notification has-background-white">
        <div>1. All alcohol sales are final (law mandated by the ABC).</div>
        <div>
          2. Kegs are not pasteurized and MUST be cold all the time (ideal temp
          is 38F).
        </div>
        <div>3. The ideal pressure is 13psi. DO NOT OVERPUMP.</div>
        <div>
          4. The keg needs to settle so do not move keg for two hours before
          tapping.
        </div>
        <div>
          5. If foaming occurs, you messed up on one or more of the keg rules.
        </div>
      </div>
    </ConstrainedCenterColumn>
    <ConstrainedCenterColumn>
      <p className="notification has-background-info">
        Most suppliers require a few days lead time, so if you need the keg for
        the weekend, please call on Monday of the same week. We always have
        around 100 kegs in stock so if you are not concerned about which beer
        you are going to find, you can choose from our huge variety on hand.
        Some rare kegs are not always in stock when we order them from the
        supplier so we may not be able to get it.
      </p>
    </ConstrainedCenterColumn>
  </SectionContainer>
);

export default KegRules;
